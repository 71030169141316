import React from "react";
import { Typography } from "@material-ui/core";
import NewsItem from "./NewsItem";
import { parseISO } from "date-fns";

export default ({ classes }) => {
  return [
    <NewsItem first time={parseISO("2025-03-26T20:41:00")} classes={classes}>
      <Typography gutterBottom>Epoch 547 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>115%</b>. This epoch represents an annualised return of{" "}
            <b>2.9%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>84%</b>. Exactly as predicted. This
            epoch’s rewards will be on the <b>30th March</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>99%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2025-03-26T20:29:00")} classes={classes}>
      <Typography gutterBottom>Epoch 546 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>76%</b>. This epoch represents an annualised return of{" "}
            <b>1.8%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>115%</b>. Exactly as predicted. This
            epoch’s rewards will be on the <b>25th March</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>84%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>244%</b>. This epoch represents an annualised return of{" "}
            <b>0.4%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2025-03-16T17:58:00")} classes={classes}>
      <Typography gutterBottom>Epoch 545 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>69%</b>. This epoch represents an annualised return of{" "}
            <b>1.6%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>76%</b>. Slightly less than
            predicted as we lost 1 slot battle, this was unavoidable. This
            epoch’s rewards will be on the <b>20th March</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>115%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>244%</b>. Slightly less than
            predicted as we lost 1 slot battle, this was unavoidable. This
            epoch’s rewards will be on the <b>20th March</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2025-03-16T13:19:00")} classes={classes}>
      <Typography gutterBottom>Epoch 544 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>115%</b>. This epoch represents an annualised return of{" "}
            <b>2.9%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>69%</b>. Slightly less than
            predicted as we lost 1 slot battle, this was unavoidable. This
            epoch’s rewards will be on the <b>15th March</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>84%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>244%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,

    <NewsItem first time={parseISO("2025-03-09T16:33:00")} classes={classes}>
      <Typography gutterBottom>Epoch 543 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Tuesday were from the previous epoch. Our luck was{" "}
            <b>139%</b>. This epoch represents an annualised return of{" "}
            <b>3.5%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>115%</b>. Slightly less than
            predicted as we lost 1 slot battle, this was unavoidable. This
            epoch’s rewards will be on the <b>5th March</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>77%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2025-03-02T20:59:00")} classes={classes}>
      <Typography gutterBottom>Epoch 542 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Tuesday were from the previous epoch. Our luck was{" "}
            <b>64%</b>. This epoch represents an annualised return of{" "}
            <b>1.5%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>139%</b>. Slightly less than
            predicted as we lost 1 slot battle, this was unavoidable. This
            epoch’s rewards will be on the <b>5th March</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>123%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Tuesday were from the previous epoch. Our luck was{" "}
            <b>256%</b>. This epoch represents an annualised return of{" "}
            <b>0.5%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2025-02-27T10:43:00")} classes={classes}>
      <Typography gutterBottom>Epoch 541 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Tuesday were from the previous epoch. Our luck was{" "}
            <b>123%</b>. This epoch represents an annualised return of{" "}
            <b>3.1%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>64%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>28th February</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>146%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>250%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>28th February</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2025-02-20T22:41:00")} classes={classes}>
      <Typography gutterBottom>Epoch 540 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Tuesday were from the previous epoch. Our luck was{" "}
            <b>69%</b>. This epoch represents an annualised return of{" "}
            <b>1.7%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>123%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>23rd February</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>64%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>250%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2025-02-14T20:23:00")} classes={classes}>
      <Typography gutterBottom>Epoch 539 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Thursday were from the previous epoch. Our luck was{" "}
            <b>107%</b>. This epoch represents an annualised return of{" "}
            <b>2.7%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>69%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>18th February</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>123%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>250%</b>. This epoch represents an annualised return of{" "}
            <b>0.5%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2025-02-12T19:37:00")} classes={classes}>
      <Typography gutterBottom>Epoch 538 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>84%</b>. This epoch represents an annualised return of{" "}
            <b>2.1%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>107%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>13th February</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>69%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>250%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>13th February</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2025-02-07T07:12:00")} classes={classes}>
      <Typography gutterBottom>Epoch 537 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>108%</b>. This epoch represents an annualised return of{" "}
            <b>2.7%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>84%</b>. Slightly less than
            predicted as we lost 1 slot battle, this was unavoidable. This
            epoch’s rewards will be on the <b>8th February</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>107%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>500%</b>. This epoch represents an annualised return of{" "}
            <b>7.5%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>250%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2025-02-02T11:43:00")} classes={classes}>
      <Typography gutterBottom>Epoch 536 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>69%</b>. This epoch represents an annualised return of{" "}
            <b>1.7%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>108%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>3rd February</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>92%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>250%</b>. This epoch represents an annualised return of{" "}
            <b>0.7%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>500%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>29th January</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>0%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
    <NewsItem first time={parseISO("2025-01-25T19:05:00")} classes={classes}>
      <Typography gutterBottom>Epoch 535 completed</Typography>
      <Typography>London Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            Rewards out on Monday were from the previous epoch. Our luck was{" "}
            <b>121%</b>. This epoch represents an annualised return of{" "}
            <b>3.1%</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>69%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>29th January</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>108%</b>
          </Typography>
        </li>
      </ul>
      <Typography>UK1 Pool</Typography>
      <ul>
        <li>
          <Typography gutterBottom>
            We finished this epoch with luck of <b>250%</b>. Exactly as
            predicted. This epoch’s rewards will be on the <b>29th January</b>
          </Typography>
        </li>
        <li>
          <Typography gutterBottom>
            Luck for the new epoch is predicted to be <b>500%</b>.
          </Typography>
        </li>
      </ul>
      <Typography gutterBottom>
        As always, if you have any questions please feel free to email us at{" "}
        <a href="mailto:support@londonpool.co.uk" className={classes.link}>
          support@londonpool.co.uk
        </a>{" "}
        and we'll do our best to help.
      </Typography>
    </NewsItem>,
  ];
};
